import React, { useEffect } from 'react';

const CursoPage = () => {
    useEffect(() => {
        window.location.replace("https://marketing.vpsdozeroaoavancado.com.br/asset/3:pagina-de-login---acesso-alunos");
    }, []);

    return (
        <>
        </>
    );
}

export default CursoPage;